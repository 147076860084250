import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { ErrorHandlerService } from './services/error-handler.service';

@Injectable({
    providedIn: 'root'
})
export class GlobalErrorHandler implements ErrorHandler {
    constructor(private injector: Injector) { }

    getService() {
        return this.injector.get(ErrorHandlerService);
    }

    handleError(err: any): void {
        this.getService().captureException(err);
    }
}