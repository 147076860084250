<div class="container-fluid navega-front">
  <div class="row justify-content-center">
    <div class="col-auto align-self-center">
      <div class="text-center">
        <img class="mb-3" src="/assets/img/notfound.png" alt="Not Found">
        <h1>404. Oops ...</h1>
        <p class="lead">The page you are looking for does not exist</p>
        <a [routerLink]="['/']">Back to main page »</a>
      </div>
    </div>
  </div>
</div>