import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ErrorModalComponent } from '../error-modal/error-modal.component';
import { HttpErrorResponse } from '@angular/common/http';
import * as Sentry from "@sentry/browser";
import { AuthService } from './auth.service';

// Init raven
if (environment.sentryDSN) {
    Sentry.init({
        dsn: environment.sentryDSN,
        environment: environment.env,
    });
}

@Injectable({
    providedIn: 'root'
})
export class ErrorHandlerService {
    constructor(
        private auth: AuthService,
        private modalService: NgbModal
    ) { }

    captureException(err: any) {
        if (err instanceof HttpErrorResponse) {
            if (err.status == 0 || !navigator.onLine) {
                err = new Error('API is not available. Try later!');
            }
        }
        if (environment.sentryDSN) {
            // const modal: NgbModalRef = this.modalService.open(ErrorModalComponent, { centered: true });
            // modal.componentInstance.extraMessage = extraMessage;
            // modal.result.then((result) => {
            //     console.log(`Accepted ${result}`);
            // }, (result) => {
            //     console.log(`Dismissed ${result}`);
            // })
            console.error(err);
            Sentry.captureException(err.originalError || err);
            // this.auth.user$.subscribe(user => {
            //     Sentry.showReportDialog({
            //         eventId: eventId,
            //         user: {
            //             email: user.email,
            //             name: user.displayName,
            //         }
            //     });
            // });
        } else {
            console.error(err);
        }
    }
}