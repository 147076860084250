import { Component } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { environment } from '@env/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(private fns: AngularFireFunctions) {
    // if (environment.env === 'local') {
    //   this.fns.functions.useFunctionsEmulator(environment.functionEmulatorUrl);
    // }
  }
}
