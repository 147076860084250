import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';



import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireFunctionsModule, ORIGIN } from '@angular/fire/functions';
import { AngularFireMessagingModule } from '@angular/fire/messaging';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';

import { environment } from '@env/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NotFoundPageComponent } from './not-found-page/not-found-page.component';
import { GlobalErrorHandler } from './error-handler';
import { ErrorModalComponent } from './error-modal/error-modal.component';


export var providers = [];
if (environment.env == "local") {
  providers = [

    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: ORIGIN, useValue: environment.functionEmulatorUrl }
  ]
  // if (environment.firestoreEmulatorHost) {
  //   providers = [...providers,
  //   {
  //     provide: FirestoreSettingsToken, useValue: {
  //       host: environment.firestoreEmulatorHost,
  //       ssl: false
  //     }
  //     }
  //   ]
  // }
} else {
  providers = [

    { provide: ErrorHandler, useClass: GlobalErrorHandler },
  ]
}

// console.log(providers);

@NgModule({
  declarations: [
    AppComponent,
    NotFoundPageComponent,
    ErrorModalComponent,
  ],
  entryComponents: [
    ErrorModalComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireDatabaseModule,
    AngularFireFunctionsModule,
    AngularFireMessagingModule,
    NgbModule,
    NgxsModule.forRoot([], {
      developmentMode: (environment.env != 'prod'),
      selectorOptions: {
        suppressErrors: false,
        injectContainerState: false,
      }
    }),
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: (environment.env == 'prod'),
      maxAge: 10,
    }),
  ],
  providers: providers,
  bootstrap: [AppComponent]
})
export class AppModule { }
