import { Injectable } from '@angular/core';
import { User, PasswordAuthCredential, SignupUser } from '@models/auth';
import { Observable, of } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireFunctions } from '@angular/fire/functions';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate } from '@angular/router';
import { DbService } from './db.service';
import { switchMap, take, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user$: Observable<User>;

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFireFunctions,
    private db: DbService,
    private router: Router,
  ) {
    this.user$ = this.afAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          // console.log('get user', user.uid);
          return this.db.doc$(`users/${user.uid}`) as Observable<User>;
        } else {
          return of(null);
        }
      })
    );
  }

  async signOut() {
    await this.afAuth.signOut();
    return this.router.navigate(['/']);
  }

  async emailSignin({ email, password }: PasswordAuthCredential) {
    const cred = await this.afAuth.signInWithEmailAndPassword(email, password);
    return cred.user;
  }

  async emailSignup(signupUser: SignupUser) {
    const cred = await this.afAuth.createUserWithEmailAndPassword(signupUser.email, signupUser.password);
    // XXX since cred.user is a complex object we should copy its selected field
    const user = {
      email: cred.user.email,
      photoURL: cred.user.photoURL,
      emailVerified: cred.user.emailVerified,
      displayName: signupUser.name,
      planID: signupUser.planID,
      paymentSourceID: signupUser.paymentSourceID,
      phoneNumber: signupUser.phoneNumber,
      organization: signupUser.organization,
      position: signupUser.position,
    }
    try {
      const f = this.afs.httpsCallable('signupUser');
      return await f(user).toPromise();
    } catch (err) {
      console.log(err);
      // delete if the something went wrong so the user can try again
      await cred.user.delete();
      throw new Error('Something bad happend! Try again later or contact the team.');
    }
  }

  async emailSignupInvite(signupUser: any) {
    const cred = await this.afAuth.createUserWithEmailAndPassword(signupUser.email, signupUser.password);
    // XXX since cred.user is a complex object we should copy its selected field
    const userInvite = {
      code: signupUser.code,
      email: cred.user.email,
      photoURL: cred.user.photoURL,
      emailVerified: cred.user.emailVerified,
      displayName: signupUser.name,
      planID: signupUser.planID,
      phoneNumber: signupUser.phoneNumber,
      organization: signupUser.organization,
      position: signupUser.position,
    }
    try {
      const f = this.afs.httpsCallable('signupUserInvite');
      return await f(userInvite).toPromise();
    } catch (err) {
      console.log(err, err.code, err.message);
      // delete if the something went wrong so the user can try again
      await cred.user.delete();
      if (err?.code === "invalid-argument") {
        throw new Error(err.message)
      } else {
        throw new Error('Something bad happend! Try again later or contact the team.');
      }
    }
  }


  async passwordResetRequest(email: string) {
    const f = this.afs.httpsCallable('forgotPassword');
    return await f({ email: email }).toPromise();
  }

  async passwordReset(token: string, password: string) {
    const f = this.afs.httpsCallable('resetPassword');
    return await f({ token: token, password: password }).toPromise();
  }

  async updateProfile(update) {
    const f = this.afs.httpsCallable('updateProfile');
    return await f(update).toPromise();
  }

  async changePassword(newPassword: string) {
    const f = this.afs.httpsCallable('changePassword');
    return await f({ password: newPassword }).toPromise();
  }

  async deleteAccount() {
    const f = this.afs.httpsCallable('deleteAccount');
    return await f({}).toPromise();
  }
}

@Injectable({
  providedIn: 'root'
})
export class LoginRequiredGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const nextPage = next.url;
    return this.auth.user$.pipe(
      // take(2),
      map(user => {
        // console.log("db user", user);
        const loggedIn = !!user;
        if (!loggedIn) {
          console.log('access denied');
          const ret = this.router.parseUrl(`/auth/login?nextPage=${nextPage}`);
          return ret;
        }
        return loggedIn;
      }));
  }
}

@Injectable({
  providedIn: 'root'
})
export class IsAlreadyLoggedInGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.auth.user$.pipe(
      take(1),
      map(user => {
        const loggedIn = !!user;
        if (!loggedIn) {
          return true
        }
        return this.router.parseUrl(`/m/dashboard`);
      }));
  }
}