import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotFoundPageComponent } from './not-found-page/not-found-page.component';
import { LoginRequiredGuard } from './services/auth.service';

const routes: Routes = [
  { path: 'auth', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) },
  { path: 'm', canActivate: [LoginRequiredGuard], loadChildren: () => import('./main/main.module').then(m => m.MainModule) },
  { path: '404', component: NotFoundPageComponent },
  { path: '', loadChildren: () => import('./front/front.module').then(m => m.FrontModule) },
  { path: '**', component: NotFoundPageComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled'
  })],
  // imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
