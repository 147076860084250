export const environment = {
    env: "test",
    functionEmulatorUrl: null,
    firestoreEmulatorHost: null,
    sentryDSN: "https://981f7ea4b02b42eba25e279c94895fdc@sentry.io/1813453",
    stripePK: 'pk_test_EH37xeG8kRQafkVUowAz2sdA00iZIrJJUp',
    firebase: {
        apiKey: "AIzaSyC7dDNBRiig3GGjqXIMQf7T7BTtVVN5Qq8",
        authDomain: "mm-test-34568.firebaseapp.com",
        databaseURL: "https://mm-test-34568.firebaseio.com",
        projectId: "mm-test-34568",
        storageBucket: "mm-test-34568.appspot.com",
        messagingSenderId: "12906220032",
        appId: "1:12906220032:web:610d00e6e03aa4f73826e5",
        measurementId: "G-V8FZNGLD4F"
    },
    contentful: {
        space: 'q4ib5mktmvd4',
        accessToken: 'YVNDsIi53gcq-HBMLfp97Ma7tJXbtzUQbCacFyOEirg',
        footerEntryId: '5dSRXu5LbpDy6PjSsZeAvc',
        homeLeadEntryId: '1bse7EiH6YEYc7I8LrbE5u',
        individualSwitchEntryId: '3HIjyqaDP5RJXLa7c2uprA',
        institutionSwitchEntryId: '4MiCLNuq21PcAtOiVhNcoT',
        problemsEntryId: '7joNwXGl5heWJxijLJ8oiE',
        solutionsEntryId: '4sNGnNtYjvGh9miaK6pwC6',
        emailCourseEntryId: '74oBDjXpCgq7rrNb9k0Osf',
        requestDemoEntryId: '3VDlZsvShZ3Uaj9tH0otIx',
        termsPageEntryId: '5nUmzyjNTjaLNwlc0q5BpX',
        privacyPageEntryId: 'e7r7PwuLDPeMJEcxJGGzO',
        featuresPageEntryId: '7x3zMKhgBxGT9CETHYgtYB',
        faqEntryId: '2ar0s5Nru44171sOTJKR7g',
        institutionLeadEntryId: '7caXeOLiaU1j9XrACC96dV',
        institutionProblemsEntryId: '7joNwXGl5heWJxijLJ8oiE',
        insititionSolutionsEntryId: '5X17aZUkVJDBruJHWBYJmy',
        requestDemoFormPageEntryId: '4qfajWp6GJSMKcne0cbBhK',
    }
};